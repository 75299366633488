<template>
  <div class="page-wrapper section-space--inner--100">
    <!--Projects section start-->
    <div class="project-section">
      <div class="container">
        <div class="row">

          <div class="col-12 section-space--bottom--40">
            <div class="project-image">
              <img :src="$d.projectList[id].imgUrl" class="img-fluid" alt="image">
            </div>
          </div>

          <div class="col-lg-4 col-12 section-space--bottom--30">
            <div class="project-information">
              <h3>项目简介</h3>
              <ul>
                <li v-for="(info,index) in $d.projectList[id].infoList" :key="index">
                  <div>{{ info.label }}</div>
                  <div>{{ info.desc }}</div>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
            <div class="project-details">
              <h2>{{ $d.projectList[id].title }}</h2>
              <p>{{ $d.projectList[id].desc }}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--Projects section end-->
  </div>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
    }
  }
}
</script>
