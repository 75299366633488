<template>
  <div class="main-container">

    <Header />

    <Breadcrumb :key="id" :items="items" :title="$d.projectList[id].title" />

    <ProjectDetailsWrapper :id="id" :key="id + 1" />

    <!-- <BrandCarousel add-class="grey-bg" /> -->

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up" />
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from '@/components/Header'
import Breadcrumb from '../components/Breadcrumb'
import ProjectDetailsWrapper from '../components/sections/ProjectDetailsWrapper'
import Footer from '../components/Footer'
import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'
export default {
  components: {
    Header,
    Breadcrumb,
    ProjectDetailsWrapper,
    Footer,
    OffCanvasMobileMenu
  },
  data() {
    return {
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    },
    items() {
      return [
        {
          text: '商业案例',
          to: '/project'
        }, {}
      ]
    }
  },
  metaInfo: {
    title: '乌节国际 - 商业案例详情',
    htmlAttrs: {
      lang: 'zh-cn',
      amp: true
    }
  }
}
</script>

